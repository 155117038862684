.account-data-prompt {
    font-weight: bold;
    margin: 1.5rem 0;
}

.account-data-prompt__header {
    margin-bottom: 0.75rem;
}

.account-data-prompt__list {
    margin: 1rem 0;
}

.account-data-prompt__list ul {
    list-style-type: disc;
    padding-left: 1.25rem;
}

.account-data-prompt__list ul li {
    color: #0052a5;
}
.account-data-prompt__list ul li span {
    color: #1b1b1b;
}
