.prompt {
    margin: -0.6rem 0 0.25rem;
}

.prompt__label {
    color: gray;
    font-size: 0.875rem;
    margin-right: 0.625rem;
    white-space: pre-wrap;
}

.prompt__label--disabled {
    color: gray;
}

.prompt__icon {
    width: 1.375rem;
    height: 1.375rem;
    position: relative;
    bottom: -5px;
    margin-right: 6px;
}

.prompt__anchor {
    display: inline-block;
    cursor: pointer;
    color: #0052a5;
    font-size: 0.875rem;
}

.prompt__star {
    color: #d5233f;
    margin-right: 4px;
}

.prompt__anchor:hover {
    color: #0052a5;
    text-decoration: none;
}
