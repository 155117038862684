.letter-left-info {
    font-size: 14px;
    text-align: right;
    padding-top: 5px;
    color: #808080;
    position: absolute;
    right: 0;
}

.letter-left-info--exceeded {
    color: #d5233f;
}
