html {
    scroll-behavior: smooth;
}

.toggle-arrow-down {
    background: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0D%0A%3Csvg%0D%0A%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0D%0A%20%20%20viewBox%3D%22-4%20-4%208%208%22%3E%0D%0A%20%20%20%0D%0A%20%20%20%3Crect%20style%3D%22fill%3A%230052a5%22%20width%3D%228%22%20height%3D%222%22%20transform%3D%22scale%280.7071067811865475%2C%200.7071067811865475%29%20rotate%28225%29%20translate%28-2.5%2C%20-2.5%29%22%2F%3E%0D%0A%20%20%20%3Crect%20style%3D%22fill%3A%230052a5%22%20width%3D%222%22%20height%3D%228%22%20transform%3D%22scale%280.7071067811865475%2C%200.7071067811865475%29%20rotate%28225%29%20translate%28-2.5%2C%20-2.5%29%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A") no-repeat;
    height: 0.75rem;
    width: 0.75rem;
    margin-left: 0.5rem;
    display:inline-block;
    overflow:hidden;
    position: relative;
    top: 2px;
}

.toggle-arrow-down.active {
    transform: scaleY(-1);
}

.contact-point {
    position: relative;
}

.contact-point-content {
    min-height: 200px;
    width: 60%;
    position: relative;
}

.form-group {
    position: relative;
}

.header {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.subheader {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
}

.anchor {
    display: block;
    cursor: pointer;
    color: #0052a5;
    font-weight: bold;
    margin-top: 1rem;
}

.anchor:hover {
    color: #0052a5;
    text-decoration: none;
}

.invalid-info {
    font-size: 0.875rem;
    color: #d5233f;
}

.form:not(:last-of-type) {
    margin-bottom: 2.625rem;
}

.form input[type="text"].invalid-input, div.invalid-input, .form textarea.invalid-input, .form select.invalid-input {
    border-bottom: 3px solid #d5233f;
}

.required-input-star {
    color: #d5233f;
}

@media only screen and (max-width: 64rem) {
    .header {
        font-size: 1.75rem;
    }

    .subheader {
        font-size: 1.3125rem;
        margin-bottom: 0.5rem;
    }
}

@media only screen and (max-width: 48rem) {
    .contact-point-content {
        width: 100%;
    }
}
