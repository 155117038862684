.custom-radio {
    margin: 0.5rem 0;
}

.custom-radio input[type="radio"]:disabled ~ label::before {
     border-color: #b7b7b7;
 }

.custom-radio input[type="radio"]:checked:disabled ~ label::after {
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23b7b7b7'/%3e%3c/svg%3e");
 }
