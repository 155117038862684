.clause__header {
    font-size: 1.5rem;
    margin: 2.25rem 0 1.25rem;
}

.clause__header--small {
    font-size: 1rem;
    margin: 1.5rem 0 0.5rem;
}

.clause__list {
    list-style-type: disc;
    padding-left: 1.25rem;
}

.clause__list li {
    color: #0052a5;
}
.clause__list li span {
    color: #1b1b1b;
}

.clause__desc--with-margin {
    margin-bottom: 1rem;
}

.clause__checkbox-label {
    margin-bottom: 0.5rem;
}
