.dropbox {
    border: 1px solid #b7b7b7;
    border-radius: 4px;
    min-height: 12.5rem;
    width: 100%;
    position: relative;
    text-align: center;
    cursor: pointer;
    padding: 1.25rem 0;
}

.dropbox__input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    cursor: pointer;
}

.dropbox__instruction {
    color: #1b1b1b;
    font-size: 1rem;
    padding: 0.625rem 0;
    width: 60%;
    margin: 0 auto;
}

.dropbox__info {
    font-size: 0.875rem;
    color: #656565;
    width: 60%;
    margin: 0 auto;
}

.dropbox__button {
    width: 11.5rem;
    height: 2.75rem;
    border-radius: 4px;
    font-size: 0.875rem;
    color: #0052a5;
    border: solid 2px #0052a5;
    background-color: #fff;
    margin: 1.25rem 0;
    font-weight: bold;
}

@media only screen and (max-width: 48rem) {
    .dropbox__instruction, .dropbox__info {
        width: 80%;
        margin: 0 auto;
    }
}
