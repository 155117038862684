.file-info-container {
    border-bottom: 2px solid #b7b7b7;
    padding: 0.625rem 0;
    display: flex;
}
.file-info-container.invalid {
    border-bottom: 2px solid #d5233f;
}

.file-info-container .file-info-container-image {
    flex: 0 0 3.125rem;
}
.file-info-container .file-info-container-data {
    flex: auto;
}
.file-info-container .file-info-container-data .file-name-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3125rem;
    font-size: 1rem;
    color: #1b1b1b;
}
.file-info-container .file-info-container-data .file-name-container .file-remove-button > img {
    max-height: 0.875rem;
    max-width: 0.875rem;
    cursor: pointer;
}
.file-info-container .file-info-container-data .file-details-container {
    display: flex;
    justify-content: space-between;
    color: #656565;
    font-size: 0.875rem;
}

.validation-message {
    color: #d5233f;
    font-size: 0.875rem;
}
