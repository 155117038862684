.buttons {
    float: right;
    margin: 2.5rem 0;
}

.buttons .btn {
    width: 10rem;
    margin-left: 1.875rem;
}

.clear-both {
    clear: both;
}

@media only screen and (max-width: 48rem) {

    .buttons {
        float: none;
        margin: 0;
    }

    .buttons .btn {
        width: 100%;
        margin-bottom: 1.25rem;
        margin-left: 0;
    }
}
