.form .custom-checkbox {
    margin: 0.5rem 0;
}

.form .custom-checkbox label {
    font-size: .875rem;
}

.form .custom-checkbox label.invalid::before {
    border-color: #d5233f;
}
