.header {
    margin-top: 3.125rem;
}

.success {
    display: flex;
    margin: 1.875rem 0;
}

.success__desc {
    font-size: 0.875rem;
}

.success__icon {
    width: 2.5rem;
    margin-right: 0.875rem;
    margin-top: 0.375rem;
}

@media only screen and (max-width: 64rem) {
    .success {
        display: block;
    }

    .success__icon {
        width: 3.125rem;
        margin: 0 0 1rem;
    }
}

@media only screen and (max-width: 48rem) {
    .success__icon {
        width: 2.5rem;
    }
}
