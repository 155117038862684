.messenger {
    width: 28.5rem;
    max-width: 100%;
    position: fixed;
    border-radius: 4px;
    border: solid 2px #d5233f;
    background-color: #fff;
    text-align: left;
    padding: 0.625rem;
    right: 40%;
    z-index: 2;
}

.messenger__close-button {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
}

.messenger__close-img {
    max-height: 1rem;
    max-width: 1rem;
    cursor: pointer;
}

.messenger__error {
    display: flex;
    margin-bottom: 1.25rem;
}

.messenger__error__icon {
    padding-right: 0.625rem;
}

.messenger__error__img {
    height: 1.5rem;
    width: 1.5rem;
}

.messenger__error__title {
    font-weight: bold;
    font-size: 0.875rem;
    padding-bottom: 0.625rem;
    padding-right: 1.5rem;
}

.messenger__error__desc {
    font-size: 0.875rem;
}

@media only screen and (max-width: 48rem) {
    .messenger {
        width: calc(100% - 2.2em);
        right: 1.1em;
    }
}
